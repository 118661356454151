<template>
    <main class="orders_wrapper">
        <div class="page-head">
            <div class="container">
                <router-link to="/">{{ lang == 'en' ? 'Home' : 'الرئيسية' }}</router-link> <i :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i> {{ lang == 'en' ? 'Account' : 'الحساب' }} <i :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i>  {{ lang == 'en' ? 'Orders' : 'عمليات الشراء' }}
            </div>
        </div>
        <div class="container">
            <div class="order-top-container">
                <h1 class="ml-auto title ">{{ lang == 'en' ? 'My Orders' : 'طلباتي' }}</h1>

                <div class="ml-auto order-sort-select">
                    {{ lang == 'en' ? 'Sort By:' : 'ترتيب حسب:' }}
                    <select name="per_pag" id="per_page" v-model="sort_by_status">
                        <option value="all" selected>{{ lang == 'en' ? 'All' : 'الجميع' }}</option>
                        <option value="completed">{{ lang == 'en' ? 'Completed' : 'مكتمل' }}</option>
                        <option value="shipped">{{ lang == 'en' ? 'Shipped' : 'شحنها' }}</option>
                        <option value="processing">{{ lang == 'en' ? 'Processing' : 'يعالج' }}</option>
                        <option value="cancelled">{{ lang == 'en' ? 'Cancelled' : 'ملغى' }}</option>
                        <option value="refunded">{{ lang == 'en' ? 'Refunded' : 'ردها' }}</option>
                        <option value="on-hold">{{ lang == 'en' ? 'On-hold' : 'في الانتظار' }}</option>
                    </select>
                </div>
            </div>
            <div class="table_wrapper" v-if="orders && orders.length > 0">
                <table>
                    <thead>
                        <tr>
                            <td>{{ lang == 'en' ? 'order' : 'الطلب' }} #</td>
                            <td>{{ lang == 'en' ? 'Status' : 'الحالة' }}</td>
                            <td>{{ lang == 'en' ? 'Payment Methode' : 'وسيلة الدفع' }}</td>
                            <td>{{ lang == 'en' ? 'Total' : 'المجموع' }}</td>
                            <td>{{ lang == 'en' ? 'Date' : 'التاريخ' }}</td>
                            <td>{{ lang == 'en' ? 'Details' : 'تفاصيل' }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders" :key="order.id" >
                            <td>{{order.name }}</td>
                            <td><span class="canceled">{{order.status }}</span></td>
                            <td>{{order.payment_method }}</td>
                            <td>{{order.total_price.toLocaleString()}} {{ lang == 'en' ? 'EGP' : 'جنيه' }}</td>
                            <td>{{order.created_at }}</td>
                            <td><button @click="this.$router.push(`/order/${order.id}`)">{{ lang == 'en' ? 'View Order' : 'عرض الطلب' }}</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h1 v-if="!orders || orders.length == 0"  style="width:100%;margin: 5rem 0px; text-align: center; color: rgb(113, 113, 113);">{{ lang == 'en' ? 'No orders yet' : 'لا توجد عمليات شراء' }}</h1>
            <div v-if="last_page > 1 && orders && orders.length > 0" class="pagination" >
                <div v-for="page_num in last_page" :key="page_num" >
                    <label :for="`page_num_${page_num}`" :class="page_num == page ? 'active' : ''">{{ page_num }}</label>
                    <input type="radio" name="page_num" :id="`page_num_${page_num}`" v-model="page" :value="page_num" @change="getOrders()">
                </div>
            </div>
        </div>
    </main>
</template>

<script>
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import axios from 'axios';

export default {
    name: 'OrdersView',
    data() {
        return {
            orders: null,
            lang: 'en',
            sort_by_status: 'all',
            per_page: 20,
            page: 1,
            total: 0,
            last_page: 0,
        }
    },
    watch:{
        sort_by_status(){
            this.page= 1;
            this.total= 0;
            this.last_page= 0;
            this.getOrders();
        }
    },
    methods: {
        async getOrders() {
            $('.loader').fadeIn().css('display', 'flex')
            try {
                const response = await axios.get(`https://api.egyptgamestore.com/api/users/orders/pagination?status=${this.sort_by_status}&per_page=${this.per_page}&page=${this.page}`,
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token'),
                            "lang": this.lang
                        },
                    }
                );
                if (response.data.status === true) {
                    $('.loader').fadeOut()
                    this.orders = response.data.data.orders
                    this.total = response.data.data.total
                    this.last_page = response.data.data.last_page
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        setLangCookies() {
            let langCheck = document.cookie.indexOf('lang')

            this.is_cookies = langCheck >= 0 ? true : false

            function getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            } // to get an cookie by name ##############################

            if (langCheck !== -1) {
                this.lang = getCookie('lang') // check lang cookie exist ##############################
            }

            if (sessionStorage.getItem("lang"))
                this.lang = sessionStorage.getItem("lang") // check lang session exist ##############################

            sessionStorage.setItem("lang", this.lang); // set lang session ##############################

            let searchParams = new URLSearchParams(window.location.search)
            if (searchParams.has('lang')) {
                this.lang = searchParams.get('lang')
                document.body.classList.add(searchParams.get('lang')) // add lang class ##############################
            } else {
                document.body.classList.add(this.lang) // add lang class ##############################
            }

        },
        getHomeData() {
            this.setLangCookies()
            this.getOrders()
        },
    },
    created() {
        this.getHomeData()
    },
    mounted() {
    },
}
</script>

<style scoped>@import './../assets/css/home.css';</style>
<style scoped>
.order-top-container{
    display: flex;
    width: 100%;
    align-items: center;
}
@media (max-width: 800px) {
    .order-top-container {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
    .title{
        margin-left: unset !important;
    }
    .order-sort-select{
        margin-left: unset !important;
    }
}
</style>