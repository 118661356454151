<template>
  <main class="contact_wrapper prvious_tickets_warpper">
    <div class="page-head">
      <div class="container">
        <router-link to="/">{{
          lang == "en" ? "Home" : "الرئيسية"
        }}</router-link>
        <i
          :class="
            lang == 'en'
              ? 'fa-solid fa-chevron-right'
              : 'fa-solid fa-chevron-left'
          "
        ></i>
        {{ lang == "en" ? "Ticket Conversation" : "اتصل بنا" }}
      </div>
    </div>
    <div class="ticket-container">
      <div class="main-container">
        <div class="left-side">
          <div class="header">
            <div>
              <i class="fa-regular fa-star favourite-icon"></i>
            </div>
            <div>
              <span class="subject">{{ ticketDetail.subject }}</span>
              <div class="subject-lower">
                <span class="creation-date">{{
                  formatDate(ticketDetail.created_at)
                }}</span>
                <span class="status">{{ ticketDetail.status }}</span>
              </div>
            </div>
            <div class="option-dropdown">
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </div>
          </div>
          <div class="conversation">
            <div v-for="reply in ticketDetail.replies" :key="reply.id">
              <div v-if="reply.replier == 'Customer'" class="message customer">
                <!-- <span class="sender">Customer</span> -->
                <div class="message-content">
                  <div class="avatar">
                    {{ ticketDetail.email.substring(0, 2) }}
                  </div>
                  <div>
                    <div class="message-date">
                      {{ formatDate(reply.created_at) }}
                    </div>
                    <div class="message-text">
                      {{ reply.message }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="message support">
                <!-- <span class="sender">Support</span> -->
                <div class="message-content">
                  <div class="avatar">SP</div>
                  <div class="message-text">{{ reply.message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="message-input">
            <input
              type="text"
              v-model="msg"
              placeholder="Write a message..."
              @keydown.enter="sendReply()"
            />
            <i class="fa-solid fa-paperclip"></i>

            <button @click="sendReply()" class="send-button">&#x27A4;</button>
          </div>
        </div>
        <div class="ticket-details">
          <div class="detail-container">
            <div class="expanel">
              <div
                class="expanel-header"
                @click="togglePanel('isOpenTicketDetail')"
                :class="{ 'expanel-header-expanded': isOpenTicketDetail }"
              >
                <h5>Ticket Details</h5>
                <i
                  :class="
                    isOpenTicketDetail
                      ? 'fas fa-chevron-up'
                      : 'fas fa-chevron-down'
                  "
                  class="expanel-header-expanded-icon"
                ></i>
              </div>
              <transition name="expanel">
                <div v-if="isOpenTicketDetail" class="expanel-body">
                  <div class="ticket-id">
                    <span>Ticket ID </span>
                    <span>{{ ticketDetail.id }}</span>
                  </div>
                  <!-- <div class="ticket-category">
                    <small>Category</small>
                    <div>General Question Or Order support</div>
                  </div> -->
                  <div class="ticket-date">
                    <small>Creation date</small>
                    <div>{{ formatDate(ticketDetail.created_at) }}</div>
                  </div>
                </div>
              </transition>
            </div>

            <div class="expanel">
              <div
                class="expanel-header"
                @click="togglePanel('isOpenUserDetail')"
                :class="{ 'expanel-header-expanded': isOpenUserDetail }"
              >
                <h5>User details</h5>
                <i
                  :class="
                    isOpenUserDetail
                      ? 'fas fa-chevron-up'
                      : 'fas fa-chevron-down'
                  "
                  class="expanel-header-expanded-icon"
                ></i>
              </div>
              <transition name="expanel">
                <div v-if="isOpenUserDetail" class="expanel-body">
                  <div class="user-details">
                    <!-- <div class="user-info">
                      <small>USER ID</small>
                      <div>[User ID]</div>
                    </div> -->
                    <!-- <div class="user-info">
                      <small>Phone number</small>
                      <div>[Phone number]</div>
                    </div> -->
                    <div class="user-info">
                      <small>Email address</small>
                      <div>{{ ticketDetail.email }}</div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <div class="expanel">
              <div
                class="expanel-header"
                @click="togglePanel('isOpenOrderDetail')"
                :class="{ 'expanel-header-expanded': isOpenOrderDetail }"
              >
                <h5>Order details</h5>
                <i
                  :class="
                    isOpenOrderDetail
                      ? 'fas fa-chevron-up'
                      : 'fas fa-chevron-down'
                  "
                  class="expanel-header-expanded-icon"
                ></i>
              </div>
              <transition name="expanel">
                <div v-if="isOpenOrderDetail" class="expanel-body">
                  <div class="order-details">
                    <div class="order-info">
                      <small>Order ID</small>
                      <span>{{ ticketDetail.order_id }}</span>
                    </div>
                    <!-- <div class="order-info">
                      <small>Phone Number from order</small>
                      <span>[Phone Number]</span>
                    </div>
                    <div class="order-info">
                      <small>Payment Method</small>
                      <span>[Payment Method]</span>
                    </div>
                    <div class="order-info">
                      <small>Delivery Time</small>
                      <span>[Delivery Time]</span>
                    </div>
                    <div class="order-info">
                      <small>Total payment</small>
                      <span>[Total payment]</span>
                    </div>
                    <div class="order-info">
                      <small>Number of ordered products</small>
                      <span>0</span>
                    </div>
                    <div class="products">
                      <small>Products</small>
                      <span>Product names from order details</span>
                    </div> -->
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="button-container">
            <button @click="closeTicketDetail()" class="resolve-button">
              Mark as resolved
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <section class="contact-info">
            <div class="container">
                <div>
                    <h1>{{ lang == 'en' ? "WE'd Love To" : 'نحن سعداء' }} <br> {{ lang == 'en' ? "Hear Form You" : "لتلقي رسالتك"}}</h1>
                    <p>{{ lang == 'en' ? "Chat with our friendly team" : 'الدردشة مع فريقنا الودود' }}</p>
                </div>
                <div>
                    <i class="fa fa-envelope"></i>
                    <h3>{{ lang == 'en' ? 'Email Us' : 'راسلنا'}}</h3>
                    <a href="mailto:info@egyptgamestore.com">
                        info@egyptgamestore.com
                    </a>
                </div>
                <div>
                    <i class="fa fa-phone"></i>
                    <h3>{{ lang == 'en' ? 'Call Us' : 'اتصل بنا' }}</h3>
                    <p>
                        {{ lang == 'en' ? 'Sunday to Thursday' : 'من الاحد الي الخميس' }} <br>
                        {{ lang == 'en' ? 'from 9 am to 10 pm' : 'من ٩ صباحا حتى ١٠ مساء' }}
                    </p>
                    <a href="tel:00201145636999">
                        00201145636999
                    </a>
                </div>
                <div>
                    <i class="fa-solid fa-landmark"></i>
                    <h3>{{ lang == 'en' ? 'Visit Us' : 'نتشرف بزيارتك' }}</h3>
                    <p>
                        {{ lang == 'en' ? '10 Mashroe Kolleyet El Banat, Naser city, Cairo Egypt' : '10 مشروع كلية البنات, مدينة نصر, القاهرة مصر' }}
                    </p>
                </div>
            </div>
        </section> -->
  </main>
</template>

<script>
global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;
import axios from "axios";

export default {
  name: "ContactView",
  data() {
    return {
      username: null,
      last_name: null,
      email: null,
      subject: null,
      msg: null,
      lang: "en",
      previousTickets: [
        {
          ticket_number: 123,
          order_number: 456,
          created_at: "3 July 2024",
          status: "pending",
        },
      ],
      isOpenTicketDetail: true,
      isOpenUserDetail: true,
      isOpenOrderDetail: true,
      ticketId: this.$route.params.id,
      ticketDetail: {
        replies: [],
      },
    };
  },
  methods: {
    setLangCookies() {
      let langCheck = document.cookie.indexOf("lang");

      this.is_cookies = langCheck >= 0 ? true : false;

      function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      } // to get an cookie by name ##############################

      if (langCheck !== -1) {
        this.lang = getCookie("lang"); // check lang cookie exist ##############################
      }

      if (sessionStorage.getItem("lang"))
        this.lang = sessionStorage.getItem("lang"); // check lang session exist ##############################

      sessionStorage.setItem("lang", this.lang); // set lang session ##############################

      let searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("lang")) {
        this.lang = searchParams.get("lang");
        document.body.classList.add(searchParams.get("lang")); // add lang class ##############################
      } else {
        document.body.classList.add(this.lang); // add lang class ##############################
      }
    },
    async sendReply() {
      $(".loader").fadeIn().css("display", "flex");
      try {
        const response = await axios.post(
          `${window.main_url}/tickets/${this.ticketId}/reply`,
          {
            message: this.msg,
          },
          {
            headers: {
              AUTHORIZATION: "Bearer " + sessionStorage.getItem("user_token"),
              lang: this.lang,
            },
          }
        );
        if (response.data.status === true) {
          document.getElementById("errors").innerHTML = "";
          let error = document.createElement("div");
          error.classList = "success";
          error.innerHTML = response.data.message;
          document.getElementById("errors").append(error);
          $("#errors").fadeIn("slow");
          $(".body input, .body textarea").val("");
          setTimeout(() => {
            $("#errors").fadeOut("slow");
            $(".loader").fadeOut();
          }, 1000);
          this.getTicketDetail();
          this.msg = "";
        } else {
          $(".loader").fadeOut();
          document.getElementById("errors").innerHTML = "";
          $.each(response.data.errors, function (key, value) {
            let error = document.createElement("div");
            error.classList = "error";
            error.innerHTML = value;
            document.getElementById("errors").append(error);
          });
          $("#errors").fadeIn("slow");

          setTimeout(() => {
            $("input").css("outline", "none");
            $("#errors").fadeOut("slow");
          }, 3500);
        }
      } catch (error) {
        document.getElementById("errors").innerHTML = "";
        let err = document.createElement("div");
        err.classList = "error";
        err.innerHTML = "server error try again later";
        document.getElementById("errors").append(err);
        $("#errors").fadeIn("slow");
        $(".loader").fadeOut();

        setTimeout(() => {
          $("#errors").fadeOut("slow");
        }, 3500);

        console.error(error);
      }
    },
    getHomeData() {
      this.setLangCookies();
    },
    togglePanel(data) {
      this[data] = !this[data];
    },
    async getTicketDetail() {
      $(".loader").fadeIn().css("display", "flex");
      try {
        const response = await axios.get(
          `${window.main_url}/tickets/${this.ticketId}`,
          {
            headers: {
              AUTHORIZATION: "Bearer " + sessionStorage.getItem("user_token"),
              lang: this.lang,
            },
          }
        );
        if (response.data.status === true) {
          this.ticketDetail = response.data.data;
          $(".loader").fadeOut();
        } else {
          $(".loader").fadeOut();
          document.getElementById("errors").innerHTML = "";
          $.each(response.data.errors, function (key, value) {
            let error = document.createElement("div");
            error.classList = "error";
            error.innerHTML = value;
            document.getElementById("errors").append(error);
          });
          $("#errors").fadeIn("slow");

          setTimeout(() => {
            $("input").css("outline", "none");
            $("#errors").fadeOut("slow");
          }, 3500);
        }
      } catch (error) {
        document.getElementById("errors").innerHTML = "";
        let err = document.createElement("div");
        err.classList = "error";
        err.innerHTML = "server error try again later";
        document.getElementById("errors").append(err);
        $("#errors").fadeIn("slow");
        $(".loader").fadeOut();

        setTimeout(() => {
          $("#errors").fadeOut("slow");
        }, 3500);

        console.error(error);
      }
    },
    async closeTicketDetail() {
      $(".loader").fadeIn().css("display", "flex");
      try {
        const response = await axios.post(
          `${window.main_url}/tickets/${this.ticketId}/close`,
          {
            headers: {
              AUTHORIZATION: "Bearer " + sessionStorage.getItem("user_token"),
              lang: this.lang,
            },
          }
        );
        if (response.data.status === true) {
          $(".loader").fadeOut();
        } else {
          $(".loader").fadeOut();
          document.getElementById("errors").innerHTML = "";
          $.each(response.data.errors, function (key, value) {
            let error = document.createElement("div");
            error.classList = "error";
            error.innerHTML = value;
            document.getElementById("errors").append(error);
          });
          $("#errors").fadeIn("slow");

          setTimeout(() => {
            $("input").css("outline", "none");
            $("#errors").fadeOut("slow");
          }, 3500);
        }
      } catch (error) {
        document.getElementById("errors").innerHTML = "";
        let err = document.createElement("div");
        err.classList = "error";
        err.innerHTML = "server error try again later";
        document.getElementById("errors").append(err);
        $("#errors").fadeIn("slow");
        $(".loader").fadeOut();

        setTimeout(() => {
          $("#errors").fadeOut("slow");
        }, 3500);

        console.error(error);
      }
    },
    /**
     * Formats a date string into the format dd/mm/yyyy.
     *
     * @param {string} dateString - The date string to be formatted.
     * @returns {string} The formatted date string or the original input if an error occurs.
     */
    formatDate(dateString) {
      try {
        // Create a new Date object from the date string
        const date = new Date(dateString);

        // Get the day, month, and year from the Date object
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
        const year = date.getFullYear();

        // Format the date in dd/mm/yyyy
        return `${day}/${month}/${year}`;
      } catch (err) {
        return dateString;
      }
    },
  },
  created() {
    this.getHomeData();
    this.getTicketDetail();
  },
};
</script>

<style scoped>
@import "./../assets/css/home.css";

.ticket-container {
  width: 60%;
  margin: auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* padding: 20px; */
  border: 1px solid #d5dfe4;
  margin-top: 1em;
}

.main-container {
  display: flex;
  /* gap: 1em; */
}
.favourite-icon {
  font-size: 20px;
  margin-top: 4px;
}
.header {
  display: flex;
  /* flex-direction: column; */
  gap: 1em;
  margin-bottom: 20px;
  padding: 1em;
  border-bottom: 1px solid #d5dfe4;
}

.subject {
  font-size: 20px;
  font-weight: bold;
}

.creation-date {
  font-size: 12px;
  color: gray;
  margin-top: 5px;
}

.status {
  background-color: #ff6347;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 2em;
}

.conversation {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 20px;
  height: calc(100vh - 325px);
  padding: 1em;
  width: 100%;
  overflow-y: auto;
}

.message {
  margin-bottom: 15px;
}

.customer .sender {
  color: blue;
}

.support {
  padding-left: 4em;
  margin-top: 2em;
}

.support .sender {
  color: green;
}

.message-content {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.avatar {
  background-color: #cefaf9;
  color: #23767e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.message-text {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px 5px 5px 0px;
  flex: 1;
  position: relative;
}

.message-date {
  font-size: 10px;
  color: gray;
  display: flex;
  justify-content: end;
}

.ticket-details {
  border: 1px solid #d5dfe4;
  padding: 10px;
  width: 100%;
  max-width: 40%;
}

.ticket-id,
.ticket-category,
.ticket-date,
.user-details,
.order-details {
  margin-bottom: 10px;
}

.user-info,
.order-info,
.products {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.order-info {
  padding-top: 4px;
  padding-bottom: 4px;
}

.user-info span,
.order-info span,
.products span {
  display: block;
  width: 100%;
}

.button-container {
  text-align: center;
  margin-top: 10px;
}

.resolve-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.resolve-button:hover {
  background-color: #0056b3;
}

.message-input {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 1em;
}

.message-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

.send-button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 8px;
}

.send-button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .ticket-container {
    width: 80%;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .subject,
  .creation-date,
  .status {
    margin-top: 5px;
  }
}

@media (max-width: 768px) {
  .ticket-container {
    width: 90%;
  }

  .header {
    align-items: flex-start;
  }

  .user-info span,
  .order-info span,
  .products span {
    width: 100%;
  }

  .resolve-button,
  .send-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .ticket-container {
    width: 100%;
    padding: 10px;
  }

  .subject {
    font-size: 16px;
  }

  .status {
    font-size: 10px;
    padding: 3px 6px;
  }

  .message-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .avatar {
    margin-bottom: 5px;
  }

  .message-text {
    width: 100%;
  }

  .message-input {
    /* flex-direction: column; */
  }

  .message-input input {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.message-text::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0px;
  border-width: 10px 10px 0px 0px;
  border-style: solid;
  /* background-color: #f0f0f0; */
  border-color: #f0f0f0 transparent transparent transparent;
}

/* /////////////////////////////////////////////////////   Expaneion panel  ///////////////// */
.expanel {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px 0;
  border: 1px solid #d5dfe4;
}

.expanel-header {
  padding: 4px;
  /* background-color: #f5f5f5; */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.expanel-header-expanded {
  border-bottom: 1px solid #d5dfe4;
}

.expanel-header-expanded-icon {
  font-size: small;
}

.expanel-body {
  padding: 10px;
  background-color: #fff;
}

.expanel-enter-active,
.expanel-leave-active {
  transition: all 0.3s ease;
}

.expanel-enter-from,
.expanel-leave-to {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.expanel-enter-to,
.expanel-leave-from {
  max-height: 200px;
  opacity: 1;
}
.detail-container {
  height: calc(100vh - 216px);
}
.option-dropdown {
  margin-left: auto;
  margin-top: 6px;
}
.left-side {
  width: 60%;
}

@media (max-width: 660px) {
  .main-container {
    flex-direction: column;
  }

  .left-side {
    width: 100%;
  }

  .ticket-details {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
  }
  .send-button {
    width: auto;
  }
}
</style>
