import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {getUser} from './assets/js/get-user'
import { globalMixin } from './common/globalMixin' // Import the mixin
import vue3GoogleLogin from 'vue3-google-login'
// import the package

import VueAwesomePaginate from "vue-awesome-paginate";

// import the necessary css file

import "vue-awesome-paginate/dist/style.css";


getUser().then(()=> {
    const app = createApp(App)

    // Globally register the mixin
    app.mixin(globalMixin)

    app.use(vue3GoogleLogin, {
        clientId: '244214142847-n1pq1f2kk17nhf3hh4p72td6l8oasur4.apps.googleusercontent.com'
      })
    app.use(VueAwesomePaginate)
    app.use(router).mount('#app')
    window.main_url = 'https://api.egyptgamestore.com/api/users'
})

